import './style.scss';
import { ConfigProvider, Segmented, Spin } from 'antd';
import { forwardRef, useContext, useEffect, useState } from 'react';
import Configuration from './optimizations/index';
import AutoScaler from './autoScaler';
import Button from 'components/button';
import { Context } from "hooks/store";
import { ReactComponent as CurrentPlanIcon } from "assets/images/currentPlanIcon.svg";
import { ReactComponent as LoadingIcon } from "assets/images/loader.svg";

const Configurations = forwardRef(({connectionId, doneRefresh}, ref) => {
    const [state] = useContext(Context);
    const [currentTabItem, setCurrentTabItem] = useState('optimizations');
    const [actionButtonTitle, setActionButtonTitle] = useState('');
    const [isActionButtonDisabled, setActionButtonDisabled] = useState(false);
    const connectionType = state?.connections.find(connection => connection.id === parseInt(connectionId))?.type;
    const isAutoScalerEnabled = ['msk', 'aiven'].includes(connectionType);
    const [submitFunc, setSubmitFunc] = useState(null);

    useEffect(() => {
        if (!isAutoScalerEnabled) {
            setCurrentTabItem('optimizations');
        }
    }, [connectionType]);

    const items = [
        {
            value: 'optimizations',
            label: 'Optimizations',
        },
        {
            value: 'retention',
            label: 'Retention',
        }
    ];

    if (isAutoScalerEnabled) {
        items.push({
            value: 'auto-scaler',
            label: 'Auto Scaler',
        });
    }

    const onSubmit = () => {
        if (submitFunc) submitFunc();
    };

    return (
        <div className="configuration-tab">
            <div className="configuration-tab-top">
                <div className="configuration-tab-top-right">
                    <ConfigProvider
                        theme={{
                            token: {
                                colorText: '#6358FA'
                            },
                            components: {
                                Segmented: {
                                    trackPadding: 4,
                                    trackBg: '#F1F3F9',
                                    itemSelectedBg: '#D1D9FF',
                                    itemHoverBg: '',
                                    itemActiveBg: ''
                                }
                            }
                        }}
                    >
                        <Segmented defaultValue={currentTabItem} onChange={(value) => setCurrentTabItem(value)}
                                   options={items}/>
                    </ConfigProvider>
                </div>
                <div className="configuration-tab-top-left">
                    {currentTabItem === 'auto-scaler' ?
                        <>
                            {state?.planProgress && Number.isInteger(state?.planProgress) && state?.planProgress < 100 &&
                                <div className="auto-scaler-progress">
                                    <div className="icon">
                                        <Spin indicator={<LoadingIcon/>}/>
                                    </div>
                                    <span>Scaling in Progress ({state?.planProgress}%)</span>
                                </div>
                            }

                            {state?.currentPlan &&
                                <div className="auto-scaler-current-plan">
                                    <div className="auto-scaler-current-plan-left">
                                        <div className="icon">
                                            <CurrentPlanIcon/>
                                        </div>
                                        <span>Current Plan:</span>
                                    </div>
                                    <div className="auto-scaler-current-plan-right">{state?.currentPlan}</div>
                                </div>
                            }
                        </>
                        :
                        <Button
                            placeholder={actionButtonTitle}
                            typeOfButton={'primary'}
                            onClick={() => onSubmit()}
                            disabled={isActionButtonDisabled}
                        />
                    }
                </div>
            </div>

            {currentTabItem === 'optimizations' && (
                <Configuration
                    connectionId={connectionId}
                    setActionButtonTitle={setActionButtonTitle}
                    onSubmit={(func) => setSubmitFunc(() => func)}
                    setActionButtonDisabled={setActionButtonDisabled}
                    page={'optimizations'}
                    ref={ref}
                    doneRefresh={doneRefresh}
                />
            )}
            {currentTabItem === 'retention' && (
                <Configuration
                    connectionId={connectionId}
                    setActionButtonTitle={setActionButtonTitle}
                    onSubmit={(func) => setSubmitFunc(() => func)}
                    setActionButtonDisabled={setActionButtonDisabled}
                    page={'retention'}
                    ref={ref}
                    doneRefresh={doneRefresh}
                />
            )}
            {currentTabItem === 'auto-scaler' && (
                <AutoScaler
                    clusterId={connectionId}
                    setActionButtonTitle={setActionButtonTitle}
                    onSubmit={(func) => setSubmitFunc(() => func)}
                    setActionButtonDisabled={setActionButtonDisabled}
                    ref={ref}
                    doneRefresh={doneRefresh}
                />
            )}
        </div>
    );
});

export default Configurations;
