import "./style.scss";
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
import Table from "components/table";
import { Spin } from "antd";
import { FiMinus, FiPlus } from "react-icons/fi";
import { parsingDate } from "services/valueConvertor";
import { useHttpRequest } from "services/http";
import Popover from "components/popover";
import { PiDotsThreeOutline } from "react-icons/pi";
import { ReactComponent as DeleteIcon } from 'assets/images/jobs/delete.svg';
import { ReactComponent as EditIcon } from 'assets/images/edit.svg';
import ConfirmationModal from "../components/confirmationModal";
import Notification from "components/notifications";
import EmptyStateAutoScaler from "./components/emptyState";
import Loader from "components/loader";
import RuleDrawer from "./components/ruleDrawer";
import { apiEndpoints } from "services/apiEndpoints";
import { conditionOptions, conditionTypeOptions } from "./components/ruleDrawer/selectOptions";
import { Context } from "hooks/store";
import Button from "components/button";
import Badge from "components/badge";
import { useLocation } from "react-router-dom";

const AutoScaler = forwardRef((
    {
        clusterId,
        setActionButtonTitle,
        setActionButtonDisabled,
        onSubmit,
        doneRefresh
    }, ref
) => {
    const httpRequest = useHttpRequest();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [expandedRowData, setExpandedRowData] = useState({});
    const [isTableLoading, setIsTableLoading] = useState(true);
    const [loadingRows, setLoadingRows] = useState({});
    const [currentItem, setCurrentItem] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [openRuleDrawer, setOpenRuleDrawer] = useState(false);
    const [state, dispatch] = useContext(Context);
    const connectionType = state?.connections.find(connection => connection.id === parseInt(clusterId))?.type;
    const [loadingRulesDrawer, setLoadingRulesDrawer] = useState(false);
    const [dataAuditing, setDataAuditing] = useState([]);

    const handleOpenAutoScalerRuleModal = (item = null) => {
        setCurrentItem(item);
        setOpenRuleDrawer(!openRuleDrawer);
    };

    useImperativeHandle(ref, () => ({
        handleRefresh() {
            return Promise.all([
                fetchData(),
                fetchProgress()
            ]).finally(() => doneRefresh());
        }
    }));

    useEffect(() => {
        setActionButtonTitle('Add New Rule');
        setActionButtonDisabled(false);
        fetchAllData();
        fetchProgress();
        const interval = setInterval(() => {
            getCurrentPlan();
            fetchProgress();
            fetchDataAuditing();
        }, 10000);
        return () => clearInterval(interval);
    }, [location.pathname]);

    const fetchAllData = () => {
        Promise.all([
            getCurrentPlan(),
            fetchData(),
            fetchDataAuditing(),
        ])
            .catch(error => {})
            .finally(() => {
                setIsLoading(false);
            });
    };

    const fetchDataAuditing = async () => {
        try {
            const response = await httpRequest('GET', apiEndpoints.GET_AUDIT_AUTO_SCALER, {}, {}, {connection_id: clusterId});
            setDataAuditing(response?.audit_logs?.sort((a, b) => new Date(b?.timestamp) - new Date(a?.timestamp)) || []);
        } catch (error) {
        }
    }

    const fetchData = async () => {
        setIsTableLoading(true);
        try {
            const response = await httpRequest('GET', apiEndpoints.AUTO_SCALER_RULES_GET, {}, {}, {connection_id: clusterId});
            const rules = response?.rules?.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at)) || [];
            setData(rules);

            if (expandedKeys.length > 0) {
                const updatedExpandedRowData = {};
                for (const key of expandedKeys) {
                    const expandedRecord = rules.find(rule => rule.id === key);
                    if (expandedRecord) {
                        updatedExpandedRowData[key] = expandedRecord?.rule;
                    }
                }
                setExpandedRowData(updatedExpandedRowData);
            }
        } catch (error) {
            setIsTableLoading(false);
        } finally {
            setIsTableLoading(false);
        }
    };

    const fetchProgress = async () => {
        try {
            const response = await httpRequest('GET', apiEndpoints.AUTO_SCALER_PROGRESS, {}, {}, {connection_id: clusterId});
            dispatch({type: 'SET_PLAN_PROGRESS', payload: response?.progress});
        } catch (error) {
        }
    };

    const getCurrentPlan = async () => {
        const connection_id = parseInt(clusterId);
        if (!connection_id || !connectionType || !['msk', 'aiven'].includes(connectionType)) return;

        try {
            const data = await httpRequest('GET', apiEndpoints.GET_CURRENT_PLAN, {}, {}, {connection_id}, true);
            dispatch({ type: 'SET_CURRENT_PLAN', payload: data?.current_plan });
        } catch (error) {}
    };

    const fetchExpandedRowData = async (record) => {
        const id = record.id;
        setLoadingRows(prev => ({...prev, [id]: true}));
        try {
            setExpandedRowData(prev => ({...prev, [id]: record?.rule}));
        } catch (error) {
            setLoadingRows(prev => ({...prev, [id]: false}));
        } finally {
            setLoadingRows(prev => ({...prev, [id]: false}));
        }
    };

    const toggleExpandedKeys = (key) => {
        setExpandedKeys((prev) => {
            const outArr = [...prev];
            if (outArr.includes(key)) {
                return outArr.filter((e) => e !== key);
            } else {
                outArr.push(key);
                return outArr;
            }
        });
    };

    const overlayStyles = {
        borderRadius: '8px',
        width: '150px',
        padding: '8px',
        zIndex: 99999,
        background: 'red',
        position: 'relative'
    };

    const TaskActionsPopover = ({record}) => {
        const [contextMenuOpenId, setContextMenuOpenId] = useState(false);

        const isEditButtonEnabled = true;
        const isDeleteButtonEnabled = true;

        return (
            <div onClick={(e) => e.stopPropagation()}>
                <Popover
                    overlayInnerStyle={overlayStyles}
                    placement={"bottom"}
                    content={
                        <>
                            {isEditButtonEnabled &&
                                <div
                                    className="sidebar-support-popover-item popover-item"
                                    onClick={() => {
                                        handleOpenAutoScalerRuleModal(record)
                                    }}
                                >
                                    <EditIcon/> Edit
                                </div>
                            }
                            {isDeleteButtonEnabled &&
                                <div
                                    className="sidebar-support-popover-item popover-item"
                                    onClick={() => {
                                        setCurrentItem(record);
                                        setModalOpen(true)
                                    }}
                                >
                                    <DeleteIcon className="icons-sidebar"/> Delete
                                </div>
                            }
                        </>
                    }
                    trigger="click"
                    onOpenChange={() => setContextMenuOpenId(prev => !prev)}
                    open={contextMenuOpenId}
                >
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <PiDotsThreeOutline fontSize={20}/>
                    </div>
                </Popover>
            </div>
        );
    };

    const columns = [
        {
            title: 'Rule name',
            key: 'rule_name',
            render: (text, record) => (
                <div className={`custom-expandable-cell ${expandedKeys.includes(record.key) ? 'expanded-row' : ''}`}>
                    <div style={{display: "flex", alignItems: "center", gap: 4, justifyContent: "center"}}>
                        <span
                            style={{display: "flex", alignItems: "center"}}
                            className={`expand-icon ${expandedKeys.includes(record.key) ? 'expanded' : ''}`}
                            onClick={() => toggleExpandedKeys(record.key)}
                        >
                            {expandedKeys.includes(record.key) ? <FiMinus size={16}/> : <FiPlus size={16}/>}
                        </span>
                        {record.rule_name}
                    </div>
                </div>
            ),
        },
        {
            title: "Created at",
            key: 'created_at',
            render: (text, record) => (<span className={"eclipse-text"}>{parsingDate(record.created_at)}</span>),
            sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
        },
        {
            title: "Mode",
            key: 'mode',
            render: (text, record) => record?.mode,
        },
        {
            title: "Created by",
            key: 'created_by_name',
            render: (text, record) => record?.created_by_name,
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => <TaskActionsPopover record={record}/>,
            width: '120px',
        }
    ];

    const expandedRowRender = (parentRecord) => {
        if (loadingRows[parentRecord.key] && !expandedRowData[parentRecord.key]) {
            return (
                <div style={{minHeight: 150, position: "relative"}}>
                    <div style={{top: '50%', left: '50%', transform: 'translate(-50%, -50%)', position: "absolute"}}>
                        <Spin/>
                    </div>
                </div>
            );
        }

        const expandedData = expandedRowData[parentRecord.id] || [];

        const dataModified = [
            {
                conditionName: "do",
                label: 'Change brokers instance type to',
                value: expandedData?.source + ' to ' + expandedData?.target,
            },
            {
                conditionName: "more than",
                title: expandedData?.monitor_window,
            }
        ];

        expandedData?.thresholds.forEach((threshold, index) => {
            const conditionName = index === 0 ? "IF" : expandedData?.operator?.toUpperCase();
            const conditionKey = Object.keys(threshold)?.find(key => key !== "label");

            dataModified.push({
                conditionName: conditionName,
                label: threshold.label,
                condition: conditionKey,
                value: threshold[conditionKey]
            });
        });

        const convertMinutesToDaysHoursMinutes = (minutes) => {
            const days = Math.floor(minutes / 1440);
            const hours = Math.floor((minutes % 1440) / 60);
            const remainingMinutes = minutes % 60;

            let result = [];

            if (days > 0) {
                result.push(`${days} day${days !== 1 ? 's' : ''}`);
            }
            if (hours > 0) {
                result.push(`${hours} hour${hours !== 1 ? 's' : ''}`);
            }
            if (remainingMinutes > 0 || result.length === 0) {
                result.push(`${remainingMinutes} minute${remainingMinutes !== 1 ? 's' : ''}`);
            }

            return result.join(', ');
        }

        const columns = [
            {
                title: "",
                key: 'operator',
                render: (text, record) => (
                    <span style={{textTransform: "uppercase", fontWeight: 500}}>{record?.conditionName}</span>),
            },
            {
                title: "",
                key: 'rule_title',
                render: (text, record) => {
                    if (record?.conditionName === 'do') {
                        return record?.label;
                    }
                    const conditionType = conditionTypeOptions[connectionType]?.find(option => option.value === record?.label);
                    return record?.conditionName === 'more than' ? convertMinutesToDaysHoursMinutes(record?.title) : record?.title || conditionType?.label;
                },
            },
            {
                title: "Condition",
                key: 'condition',
                render: (text, record) => {
                    if (record?.title) {
                        return {
                            children: null,
                            props: {
                                style: {display: 'none'},
                            },
                        };
                    }

                    const unitMapping = {
                        percent: '%',
                        number: '',
                        hour: '',
                        ms: 'ms',
                        'MiB/s': ' MiB/s',
                    };

                    const condition = conditionOptions?.find(option => option.value === record?.condition)?.label;
                    const conditionType = conditionTypeOptions[connectionType]?.find(option => option.value === record?.label);

                    return (
                        <div style={{display: "flex", gap: 3}}>
                            <span>{condition}</span>
                            <span>{record?.value}{unitMapping[conditionType?.unit]}</span>
                        </div>
                    );
                },
                onCell: (record) => ({
                    colSpan: record?.title ? 2 : 1,
                }),
            },
        ];

        const adjustedColumns = columns.map((col, index) => {
            if (index === 1) {
                return {
                    ...col,
                    onCell: (record) => ({
                        colSpan: record?.title ? 2 : 1,
                    }),
                };
            }
            return col;
        });

        return (
            <Table
                columns={adjustedColumns}
                dataSource={dataModified?.reverse()}
                dataKey="id"
                pagination={false}
                bordered={true}
                className="inner-table"
                showHeader={false}
            />
        );
    };

    const onExpand = (expanded, record) => {
        const keys = expanded ? [...expandedKeys, record.key] : expandedKeys.filter(key => key !== record.key);
        setExpandedKeys(keys);

        if (expanded) {
            fetchExpandedRowData(record).catch();
        }
    };

    const onDeleteRule = async () => {
        try {
            await httpRequest('POST', apiEndpoints.AUTO_SCALER_RULE_REMOVE, {
                connection_id: parseInt(clusterId),
                rule_name: currentItem.rule_name
            })
                .then(() => {
                    setData(data.filter((item) => item.id !== currentItem.id));
                    fetchDataAuditing();
                    Notification('success', 'The rule has been successfully deleted.', 1.5);
                })
        } catch (err) {
        }
    };

    const handleSubmitRule = async (values, actionType) => {
        setLoadingRulesDrawer(true);
        const actions = {
            create: {
                endpoint: apiEndpoints.AUTO_SCALER_RULES_SET,
                message: 'The rule has been successfully added.'
            },
            update: {
                endpoint: apiEndpoints.AUTO_SCALER_RULE_EDIT,
                message: 'The rule has been successfully updated.'
            }
        }

        try {
            return await httpRequest('POST', actions[actionType].endpoint, values)
                .then(() => {
                    Notification('success', actions[actionType].message, 2);
                    Promise.all([
                        fetchData(),
                        fetchDataAuditing(),
                    ]).finally(() => setOpenRuleDrawer(false));
                })
                .catch((err) => {
                    let errorMessage = err.data?.message || 'Please try again, something went wrong!';
                    try {
                        const parsedMessage = JSON.parse(errorMessage);
                        if (Array.isArray(parsedMessage)) {
                            errorMessage = parsedMessage.join(' ');
                        }
                    } catch (e) {}

                    Notification('error', errorMessage, 5);
                })
                .finally(() => {
                    setLoadingRulesDrawer(false);
                });
        } catch (err) {
        }
    }

    const columnsAuditing = [
        {
            title: 'Timestamp',
            key: 'timestamp',
            dataKey: 'timestamp',
            render: (text, record) => parsingDate(record?.timestamp),
            sorter: (a, b) => new Date(a.timestamp) - new Date(b.timestamp),
            width: '15%',
        },
        {
            title: 'Rule name',
            key: 'rule_name',
            dataKey: 'rule_name',
            render: (text, record) => record?.rule_name || "-",
            width: '15%',
        },
        {
            title: 'Status',
            key: 'severity',
            dataKey: 'severity',
            render: (text, record) => {
                const status = record?.severity?.toLowerCase() || "-";
                if (!status) return;

                const getStatus = () => {
                    switch (status) {
                        case 'completed':
                            return 'success';
                        case 'failed':
                            return 'danger';
                        case 'error':
                            return 'danger';
                        case 'info':
                            return 'white';
                        default:
                            return 'default';
                    }
                }

                return (
                    <Badge
                        type={getStatus(status)}
                        text={status}
                        style={{minWidth: 70, borderRadius: 10, padding: '4px 10px', display: 'flex', justifyContent: 'center'}}
                    />
                );
            },
            width: '12%',
        },
        {
            title: 'Message',
            key: 'message',
            dataKey: 'message',
            render: (text, record) => record?.message,
        },
    ];

    return isLoading ? <Loader background={false}/> : (
        <div className="auto-scaler">

            <div className="top">
                <div className="heading">
                    <div className="heading-left">
                        <div className="heading-title">Rules list</div>
                        <div className="heading-subtitle">Manage your subscription efficiently with automated rules. Add,
                            edit, or remove rules to upgrade or downgrade your paid plan based on your specific needs and
                            usage patterns.
                        </div>
                    </div>
                    <div className="heading-right">
                        <Button
                            placeholder="Add New Rule"
                            typeOfButton={"primary"}
                            onClick={() => handleOpenAutoScalerRuleModal()}
                        />
                    </div>
                </div>
                <div className="auto-scaler-table-rules">
                    {data?.length === 0 ? <EmptyStateAutoScaler onSubmit={() => handleOpenAutoScalerRuleModal()}/> :
                        <Table
                            columns={columns}
                            expandable={{
                                onExpand,
                                expandedRowRender,
                                expandIconColumnIndex: -1,
                                expandedRowKeys: expandedKeys,
                                expandRowByClick: true,
                            }}
                            rowClassName={(record) => (expandedKeys.includes(record.key) ? 'expanded-row' : '')}
                            dataSource={data?.map((item) => ({...item, key: item?.id}))}
                            pagination={false}
                            bordered
                            scroll={{x: '100%', y: 'calc( 100vh - 705px )'}}
                            loading={isTableLoading}
                        />
                    }
                </div>
            </div>

            {dataAuditing.length > 0 && (
                <div className="bottom">
                    <div className="heading">
                        <div className="heading-left">
                            <div className="heading-title">Auditing</div>
                        </div>
                        <div className="heading-right"></div>
                    </div>
                    <div className="auto-scaler-table-audit">
                        <Table
                            columns={columnsAuditing}
                            dataSource={dataAuditing?.map((item) => ({...item, key: item?.id}))}
                            pagination={false}
                            bordered
                            scroll={{x: '100%', y: 'calc( 100vh - 675px )'}}
                        />
                    </div>
                </div>
            )}

            <ConfirmationModal
                setModalOpen={setModalOpen}
                isModalOpen={isModalOpen}
                rule_name={currentItem?.name}
                onDelete={onDeleteRule}
            />

            <RuleDrawer
                open={openRuleDrawer}
                setDrawerOpen={setOpenRuleDrawer}
                onSubmit={handleSubmitRule}
                clusterId={clusterId}
                currentItem={currentItem}
                loading={loadingRulesDrawer}
            />
        </div>
    );
});

export default AutoScaler;